export const queryCodes = [
  "ml",
  "sl",
  "sp",
  "rel_jja",
  "rel_jjb",
  "rel_syn",
  "rel_trg",
  "rel_ant",
  "rel_spc",
  "rel_gen",
  "rel_com",
  "rel_par",
  "rel_bga",
  "rel_bgb",
  "rel_rhy",
  "rel_nry",
  "rel_hom",
  "rel_cns",
];
